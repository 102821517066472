/* @font-face {
    src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
} */

/* @font-face {
    src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
} */
:root {
    --text-color: #101010;
    --footer-color: #8A938C;
    --footer-text-color: #006442;
    --radius: 2rem;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: linear-gradient(146deg, #096dd9 24%, rgb(255 220 176) 100%) no-repeat fixed;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

input,
textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

}

input:focus-within,
textarea:focus-within {
    background: none;
    box-shadow: black 0px 0px 0px 1.5px;
}

.card-header-text {
    color: #225ec5;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-color);
    line-height: 1.18;
}

p {
    color: var(--text-color);
    font-size: 21px;
    line-height: 1.41;
}

a {
    text-decoration: none;
    outline: none;
    color: #5af26b;
}

a:hover {
    color: #f88400;
}

*:focus {
    outline: none;
}

.about-block-image svg {
    text-align: center;
}

.premium-border {
    border-radius: var(--radius);
    border: 10px solid transparent;
    background: linear-gradient(45deg,red,blue) border-box;
    mask-composite: exclude;
    color: #eee;
}


.ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
}

.ant-drawer-content-wrapper {
    width: 300px !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.check-icon-container {
    margin-top: 0.4rem;
}

*,
::after,
::before {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

::after,
::before {
    --tw-content: ''
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h2,
h3,
h4,
h5,
h6 {
    font-size: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
pre,
samp {
    font-family: var(--font-mono), ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-feature-settings: normal;
    font-variation-settings: normal;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit
}

button,
select {
    text-transform: none
}

button,
input:where([type=button]),
input:where([type=reset]),
input:where([type=submit]) {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h5,
h6,
hr,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

menu,
ol,
ul {
    list-style: initial;
    margin: 0;
    padding: 0
}

dialog {
    padding: 0
}

textarea {
    resize: vertical
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

[role=button],
button {
    cursor: pointer
}

:disabled {
    cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
    display: block;
    vertical-align: middle
}

video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

* {
    border-color: hsl(var(--border))
}

html {
    scroll-behavior: smooth
}

/* body {
font-synthesis-weight: none;
text-rendering: optimizeLegibility;
} */

*,
::before,
::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
    --tw-contain-size: ;
    --tw-contain-layout: ;
    --tw-contain-paint: ;
    --tw-contain-style:
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 1400px) {
    .container {
        max-width: 1400px
    }
}

.\!visible {
    visibility: visible !important
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.mb-12 {
    margin-bottom: 3rem
}

.mb-2 {
    margin-bottom: 0.5rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mr-2 {
    margin-right: 0.5rem
}

.mr-4 {
    margin-right: 1rem
}

.mt-8 {
    margin-top: 2rem
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.grid {
    display: grid
}

.h-11 {
    height: 2.75rem
}

.h-5 {
    height: 1.25rem
}

.h-6 {
    height: 1.5rem
}

.w-5 {
    width: 1.25rem
}

.w-6 {
    width: 1.5rem
}

.w-full {
    width: 100%
}

.max-w-5xl {
    max-width: 64rem
}

.max-w-md {
    max-width: 28rem
}

.flex-col {
    flex-direction: column
}

.items-start {
    align-items: flex-start
}

.items-center {
    align-items: center
}

.justify-center {
    justify-content: center
}

.gap-12 {
    gap: 3rem
}

.gap-8 {
    gap: 2rem
}

.space-y-1> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-1\.5> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
}

.space-y-2> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.space-y-4> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.whitespace-nowrap {
    white-space: nowrap
}

.rounded-lg {
    border-radius: var(--radius)
}

.rounded-md {
    border-radius: calc(var(--radius) - 2px)
}

.border {
    border-width: 2px
}


.border-input {
    border-color: hsl(var(--input))
}

.bg-background {
    background-color: hsl(var(--background))
}

.bg-card {
    background-color: hsl(var(--card))
}

.bg-green-500\/20 {
    background-color: rgb(255 114 50 / 90%);
    border-radius: 2.2rem;
}

.bg-muted {
    background-color: hsl(var(--muted))
}

.bg-primary {
    background-color: hsl(var(--primary))
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.from-green-500 {
    --tw-gradient-from: #22c55e var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-green-300 {
    --tw-gradient-to: #86efac var(--tw-gradient-to-position)
}

.p-6 {
    padding: 1.5rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.rounded-corner-10px {
    border-radius: 10px;
}

.img-center-80 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.img-center-60 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}

.font-bold {
    font-weight: 700
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.leading-none {
    line-height: 1
}

.tracking-tight {
    letter-spacing: -0.025em
}

.text-card-foreground {
    color: black;
}

.text-foreground {
    color: hsl(var(--foreground))
}

.text-green-50 {
    --tw-text-opacity: 1;
    color: rgb(240 253 244 / var(--tw-text-opacity))
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))
}

.text-blue-500 {
    text-decoration: underline;
    --tw-text-opacity: 1;
    color: rgb(34 94 197 / var(--tw-text-opacity))
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(34 94 197 / var(--tw-text-opacity))
}

.footnote {
    font-size: smaller;
}

.text-muted-foreground {
    color: hsl(var(--muted-foreground))
}

.text-primary-foreground {
    color: hsl(var(--primary-foreground))
}

.\!opacity-100 {
    opacity: 1 !important
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ring-offset-background {
    --tw-ring-offset-color: hsl(var(--background))
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.transition-colors {
    transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}

.duration-300 {
    transition-duration: 300ms
}

.duration-150 {
    transition-duration: 150ms
}

@keyframes enter {
    from {
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))
    }
}

@keyframes exit {
    to {
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))
    }
}

.duration-300 {
    animation-duration: 300ms
}

.duration-150 {
    animation-duration: 150ms
}

@media (max-width: 640px) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem
    }
}

.hover\:bg-accent:hover {
    background-color: hsl(var(--accent))
}

.hover\:bg-primary\/90:hover {
    background-color: hsl(var(--primary) / 0.9)
}

.hover\:text-accent-foreground:hover {
    color: hsl(var(--accent-foreground))
}

.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus-visible\:ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-visible\:ring-ring:focus-visible {
    --tw-ring-color: hsl(var(--ring))
}

.focus-visible\:ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px
}

.disabled\:pointer-events-none:disabled {
    pointer-events: none
}

.disabled\:opacity-50:disabled {
    opacity: 0.5
}

.dark\:bg-background:is(.dark *) {
    background-color: hsl(var(--background))
}

.dark\:bg-card:is(.dark *) {
    background-color: hsl(var(--card))
}

.dark\:bg-muted:is(.dark *) {
    background-color: hsl(var(--muted))
}

.dark\:text-card-foreground:is(.dark *) {
    color: hsl(var(--card-foreground))
}

.dark\:text-foreground:is(.dark *) {
    color: hsl(var(--foreground))
}

.dark\:text-muted-foreground:is(.dark *) {
    color: hsl(var(--muted-foreground))
}

@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .md\:p-10 {
        padding: 2.5rem
    }

    .md\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .md\:py-32 {
        padding-bottom: 8rem
    }

    .md\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .md\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .md\:text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }

    .md\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }
}

.divider-wrapper:before, .divider-wrapper:after {
    content: "";
    border-bottom: 1px solid #0009;
    flex: 1 0 auto;
    height: .5em;
    margin: 0;
}

.divider-wrapper {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    border: none;
    margin: 0;
    padding: 24px 0 0;
    align-items: center;
    justify-content: center;
    vertical-align: baseline;
}

.divider-wrapper span{
    padding: 4px 10px 0 10px;
}